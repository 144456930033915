<template>
  <v-app
    id="inspire"
  >
    <v-progress-linear
      :active="loading"
      :indeterminate="loading"
      absolute
      top
    />
    <v-main class="pb-13">
      <router-view />

      <!--<v-container class="text-center dummy">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              v-bind="attrs"
              v-on="on"
            >
              {{ langs[$i18n.locale] }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(lang, langSlug) in langs"
              :key="langSlug"
              @click="setLang(langSlug)"
            >
              <v-list-item-title>{{ lang }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-container>-->
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'App',
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Accueil',
    // all titles will be injected into this template
    titleTemplate: '%s | Espace client Piscinelle'
  },
  data: () => ({

    langs: {
      fr: 'Français',
      en: 'English'
    }
  }),
  computed: {
    ...mapState('loader', ['loading'])
  },
  created: function () {
    this.$api.interceptors.response.use(undefined, (err) => {
      return new Promise(() => {
        if (err.response.status === 401 && err.response.config && !err.response.config.__isRetryRequest) {
          this.$store.dispatch('logout').then(() => {
            this.$router.push({ name: 'Login' })
          })
        }
        if (err.response.status === 404) {
          this.$router.replace({ name: '404', params: { lang: this.$i18n.locale } })
        }
        throw err
      })
    })
    this.$api.interceptors.request.use(
      config => {
        if (config.showLoader) {
          this.$store.dispatch('loader/pending')
        }
        return config
      },
      error => {
        if (error.config.showLoader) {
          this.$store.dispatch('loader/done')
        }
        return Promise.reject(error)
      }
    )
    this.$api.interceptors.response.use(
      response => {
        if (response.config.showLoader) {
          this.$store.dispatch('loader/done')
        }

        return response
      },
      error => {
        const response = error.response

        if (response.config.showLoader) {
          this.$store.dispatch('loader/done')
        }

        return Promise.reject(error)
      }
    )
  },
  methods: {
    /* setLang (langSlug) {
      this.$router.replace({ params: { lang: langSlug } })
    } */
  }
}
</script>
