<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
      >
        <div class="text-h4">
          {{ $t('page-non-trouvee-titre') }}
        </div>
        {{ $t('page-non-trouvee-texte') }}
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'PageNotFound'
}
</script>
