<template>
  <v-card
    :max-width="$vuetify.breakpoint.xs ? '400px' : $vuetify.breakpoint.sm ? '600px' : '800px'"
    class="mx-auto mt-10 mb-5"
  >
    <v-container class="pa-0">
      <v-row dense>
        <v-col
          cols="12"
          class="py-0"
        >
          <v-card flat>
            <div class="d-flex flex-column flex-sm-row flex-nowrap justify-space-between">
              <v-img
                height="auto"
                :max-width="$vuetify.breakpoint.xs ? '100%' : '250px'"
                :src="$vuetify.breakpoint.xs ? 'https://www.piscinelle.com/upload/maison-design-avec-piscine-L-nologo.jpg' : 'https://www.piscinelle.com/upload/photo-piscine-toit-terrasse-a-paris-avec-terrasse-mobile2-L-nologo.jpg'"
              >
                <v-img
                  class="ma-3"
                  src="https://www.piscinelle.com/img/logo.svg"
                  max-width="50"
                />
              </v-img>
              <div>
                <v-card-title class="text-center d-block">
                  {{ $t('connexion-ec') }}
                </v-card-title>
                <v-card-text class="py-0">
                  <v-stepper
                    v-model="step"
                    flat
                    width="100%"
                  >
                    <v-alert
                      v-if="errorMessage"
                      type="error"
                      dense
                      text
                    >
                      {{ errorMessage }}
                    </v-alert>
                    <v-alert
                      v-if="successMessage"
                      type="success"
                      dense
                      text
                    >
                      {{ successMessage }}
                    </v-alert>

                    <v-stepper-items>
                      <v-stepper-content step="1">
                        <v-form
                          ref="formMail"
                          v-model="isEmailFormValid"
                          @submit.prevent="generateSecretCode"
                        >
                          <p>
                            {{ $t('connectez-vous-ec') }}
                          </p>
                          <v-text-field
                            v-model="email"
                            type="email"
                            label="exemple@gmail.com"
                            value=""
                            filled
                            prepend-inner-icon="mdi-email"
                            single-line
                            :rules="[rules.required, rules.email]"
                            required
                          />
                          <v-btn
                            ref="submitFormMail"
                            depressed
                            class="float-right"
                            type="submit"
                            :class="!loading && isEmailFormValid ? 'background-primary primary-hover white--text' : ''"
                            :loading="loading"
                            :disabled="loading || !isEmailFormValid"
                            full-width
                            large
                          >
                            {{ $t('se-connecter') }}
                          </v-btn>
                        </v-form>
                      </v-stepper-content>
                      <v-stepper-content
                        class="text-center py-0"
                        step="2"
                      >
                        <p v-if="codeInvalide">
                          <v-btn
                            color="primary"
                            :loading="loadingNouveauCode"
                            @click="generateSecretCode(true)"
                          >
                            {{ $t('generer-secret-code') }}
                          </v-btn>
                          <span class="muted--text d-block my-2">{{ $t('code-envoye', {email: email}) }}</span>
                        </p>
                        <p v-else>
                          {{ $t('mail-connexion', {email: email}) }}
                        </p>
                        <v-divider class="my-3" />
                        <p class="mb-0">
                          {{ $t('lien-direct-ec') }}
                        </p>
                        <p class="muted--text mb-0">
                          {{ $t('ou') }}
                        </p>
                        <p>
                          {{ $t('code-connexion') }}
                        </p>
                        <v-form
                          ref="formLogin"
                          @submit.prevent="login"
                        >
                          <v-text-field
                            ref="secretCode"
                            v-model="secretCode"
                            class="secret-code-input"
                            type="tel"
                            :label="$t('code-connexion-label')"
                            placeholder="______"
                            filled
                            maxlength="6"
                            required
                            autocomplete="off"
                            outlined
                            @input="handleCodeInput"
                            @keydown="handleCodeKeyDown"
                          />
                          <a
                            class="black--text hover-underline mb-4 float-left"
                            @click="goBack()"
                          >
                            <v-icon
                              color="black"
                              class="vertical-baseline mr-1"
                              small
                            >
                              mdi-arrow-left
                            </v-icon>{{ $t('revenir') }}
                          </a>
                          <v-btn
                            depressed
                            type="submit"
                            color="primary"
                            class="float-right"
                            :loading="loading"
                            :disabled="loading || secretCode.length < 6"
                            full-width
                          >
                            {{ $t('connexion') }}
                          </v-btn>
                        </v-form>
                      </v-stepper-content>
                    </v-stepper-items>
                  </v-stepper>
                  <p class="my-5 laius-login">
                    {{ $t('laius') }} <a href="https://www.piscinelle-accessoires.com/"> https://www.piscinelle-accessoires.com/ </a>
                  </p>
                </v-card-text>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Login',
  data () {
    return {
      loading: false,
      loadingNouveauCode: false,
      email: '',
      isEmailFormValid: false,
      codeInvalide: false,
      secretCode: '',
      errorMessage: '',
      successMessage: '',
      step: 1,
      rules: {
        required: value => !!value || this.$t('champ-requis'),
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || this.$t('mail-valide')
        }
      }
    }
  },
  mounted () {
    if (this.$route.query.email && this.$route.query.secretCode) {
      this.email = this.$route.query.email
      this.secretCode = this.$route.query.secretCode
      this.step = 2
      this.handleLogin()
    }
    if (this.$route.query.post_config && this.$route.query.email) {
      this.email = this.$route.query.email
      if (this.$route.query.send_mail) {
        this.step = 2
      } else {
        this.generateSecretCode()
      }
    }
  },
  methods: {
    ...mapActions(['login']),
    generateSecretCode (nouveau = false) {
      if (nouveau === true) {
        this.loadingNouveauCode = true
      } else {
        this.loading = true
      }

      this.$api.get('/espace-client/generate-code/?email=' + this.email).then(res => {
        this.step = 2
        if (nouveau === true) {
          this.successMessage = this.$t('envoie-code', { email: this.email })
          this.loadingNouveauCode = false
        } else {
          this.loading = false
        }
        this.errorMessage = false
      }).catch(_ => {
        this.errorMessage = this.$t('email-introuvable')
        this.loading = false
      })
    },
    goBack () {
      this.step = 1
    },
    handleCodeKeyDown (e) {
      // 17 & 86 = ctrl + v
      if ((isNaN(e.key) || e.keyCode === 32 || this.secretCode.length === 6) && e.keyCode !== 8 && e.keyCode !== 17 && e.keyCode !== 86) e.preventDefault()
      return true
    },
    handleCodeInput (val) {
      if (val.length === 6) {
        this.handleLogin()
        this.$refs.secretCode.blur()
      }
    },
    handleLogin () {
      this.loading = true
      this.login({
        email: this.email,
        secretCode: this.secretCode
      })
        .then(() => {
          if (typeof (this.$route.query.redirect) !== 'undefined') {
            this.$router.push({ path: this.$route.query.redirect })
          } else {
            this.$router.push({ name: 'Home' }).catch(() => {})
          }
        })
        .catch((error) => {
          this.errorMessage = this.$t(error.response.data.error)

          if (error.response.data.error === 'code-non-valide') {
            this.codeInvalide = true
          }

          this.loading = false
          this.secretCode = ''
        })
    }
  }
}
</script>
<style scoped>
.secret-code-input input {
  font-size: 22px;
  letter-spacing: 7px;
  margin-top: 0px !important;
}
</style>
