export default [
  {
    path: 'presentation/:id',
    name: 'Presentation',
    component: () => import('../views/Presentation.vue'),
    meta: {
      anonymous: true
    }
  }
]
