import Vue from 'vue'

// Components
import App from './App.vue'

// Plugins with config for vue instance
import store from './store'
import vuetify from './plugins/vuetify'
import router from './router'
import i18n from './plugins/i18n'

// Plugins without config for vue instance
import './plugins/vue2-filters'
import './plugins/axios'
import './plugins/directives'
import './plugins/vue-meta'

// Css for webpack compilation
import './assets/scss/main.scss'
import '@fancyapps/ui/dist/fancybox.css'

// EventBus
export const bus = new Vue()

// Config
Vue.config.productionTip = false

// Global variable
Vue.prototype.$app_id = 140586622674265

// si token fourni en url, on, le met dans le local storage
const urlSearchParams = new URLSearchParams(window.location.search)
const params = Object.fromEntries(urlSearchParams.entries())

if (params.token) {
  localStorage.setItem('token', params.token)
  localStorage.setItem('interne', 1)
}

if (params.admin) {
  sessionStorage.setItem('admin', 1)
}

if (localStorage.getItem('interne') === null) {
  localStorage.setItem('interne', 0)
}

// Check si token présent dans localstorage, récupation des infos contact ou déconnexion si erreur
const token = localStorage.getItem('token')
if (token) {
  store.dispatch('loadCurrentUser', token)
    .then(() => {
      Vue.prototype.$api.defaults.headers.common.Authorization = token
    })
    .catch(_ => {
      store.dispatch('logout').then(() => {
        router.push({ name: 'Login' })
      })
    })
}

// Récupérer les taux
Vue.prototype.$api.get('/espace-client/taux-financement')
  .then(function (response) {
    const reponse = JSON.stringify(response.data)
    // localStorage.setItem('taux', JSON.stringify(response.data))

    // Init objet taux
    // const taux = JSON.parse(localStorage.getItem('taux'))
    const taux = JSON.parse(reponse)

    Vue.mixin({
      methods: {
        vpm (mtt, mens) { // Financement
          if (typeof (mens) === 'undefined') mens = 180

          // var coefReport = 1
          // var tauxAnnuel = 0.0511

          return Math.round(mtt * taux.coef_report * (taux.taux_annuel / 12) / (1 - (1 / Math.pow((1 + taux.taux_annuel / 12), mens))) * 100) / 100
          // return Math.round(mtt * coefReport * (tauxAnnuel / 12) / (1 - (1 / Math.pow((1 + tauxAnnuel / 12), mens))) * 100) / 100
        },
        taeg () {
          // var taeg = '5.46%'
          return taux.TAEG
          // return taeg
        },
        today () {
          const current = new Date()
          var month = '' + (current.getMonth() + 1)
          var day = '' + current.getDate()
          var year = current.getFullYear()
          if (month.length < 2) { month = '0' + month }
          if (day.length < 2) { day = '0' + day }

          const date = `${year}-${month}-${day}`
          return date
        },
        isAdmin () {
          if (sessionStorage.getItem('admin')) {
            return true
          }
          return false
        },
        remarque_ligne_client (remarque) {
          if (remarque.startsWith('client')) {
            return true
          }
          return false
        },
        getModeAffichagePrix (commande, user) {
          var modeAffichagePrix = 0

          var forceComptant = 0

          if (commande.ce_mtt_client_ttc > 75000 || (commande.offre_liee && commande.offre_liee.ce_mtt_client_ttc > 75000)) {
            forceComptant = 1
          }

          if (commande.statut === 'commande' || commande.statut === 'facture' || user.pays === 'BE' || (commande.type_cmd === '6' && commande.is_install_ppd === false) || forceComptant || commande.hide_financement) {
            modeAffichagePrix = 2 // Comptant seul
          } else {
            modeAffichagePrix = 1 // Financement + comptant
          }

          if (commande.type_cmd === '8') modeAffichagePrix = 3 // Financement seul

          return modeAffichagePrix
        }
      }
    })

    // Init first vue instance
    new Vue({
      router,
      store,
      vuetify,
      i18n,
      render: h => h(App)
    }).$mount('#app')
  })
