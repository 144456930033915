import Login from '@/views/Authentification/Login.vue'

export default [
  {
    path: 'login',
    name: 'Login',
    component: Login,
    meta: {
      requiresAnonymous: true
    }
  }
]
