import axios from 'axios'
import Vue from 'vue'

const Axios = {
  install (Vue) {
    Vue.prototype.$api = axios.create({
      baseURL: process.env.VUE_APP_EXTRANET_URL
    })
    Vue.prototype.$api.defaults.showLoader = true
  }
}

Vue.use(Axios)
