import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Home from '../views/Home.vue'
import PageNotFound from '../views/PageNotFound.vue'
import presentationRoutes from './presentation'
import authenticationRoutes from './authentication'
import commandeRoutes from './commande'
import photosRoutes from './photos'
import commandesRoutes from './commandes'

import i18n from '../plugins/i18n'

Vue.use(VueRouter)

const routes = [
  {
    path: '/:lang(fr|en)?',
    component: {
      render: h => h('router-view')
    },
    children: [
      {
        path: '/',
        name: 'Home',
        component: Home,
        beforeEnter: (to, from, next) => {
          store.dispatch('getCommandes').then((commandes) => {
            if (commandes.length && !new URLSearchParams(window.location.search).get('cmd')) {
              next({ name: 'Commande', params: { id: commandes[0].id } })
            } else if (commandes.length && new URLSearchParams(window.location.search).get('cmd')) {
              next({ name: 'Commande', params: { id: new URLSearchParams(window.location.search).get('cmd') } })
            } else {
              next({ name: 'Commandes' })
            }
          }).catch(() => {})
        }
      },
      ...authenticationRoutes,
      ...commandeRoutes,
      ...photosRoutes,
      ...presentationRoutes,
      ...commandesRoutes,
      {
        path: '404',
        name: '404',
        alias: '*',
        component: PageNotFound,
        meta: {
          anonymous: true
        }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  i18n.locale = to.params.lang || i18n.locale
  // Redirect to locale url based on from
  if (
    from.params.lang &&
    !to.params.lang
  ) {
    try {
      next({ path: '/' + from.params.lang + to.fullPath })
    } catch (error) {
      if (error.name === 'NavigationDuplicated') {
        // Do nothing
      } else {
        // next({ name: 'Login', params: { lang: to.params.lang } })
        throw error
      }
    }
    return
  }

  if (to.matched.some(record => record.meta.anonymous)) {
    // Page avec meta anonymous: Visitable sans restriction (connecté / anonyme)
    next()
    return
  } else if (to.matched.some(record => record.meta.requiresAnonymous) && store.getters.isLoggedIn) {
    // Page avec meta requiresAnonymous: visitable que en anonyme
    next({ name: 'Home', params: { lang: to.params.lang } })
    return
  } else if (!store.getters.isLoggedIn && to.name !== 'Login' && new URLSearchParams(window.location.search).get('post_config')) {
    const email = new URLSearchParams(window.location.search).get('email')
    router.push({ path: 'login', query: { post_config: 'true', email: email, redirect: to.fullPath } })
    return
  } else if (!store.getters.isLoggedIn && to.name !== 'Login') {
    // Quand déconnecté, redirection vers le login
    router.push({ path: 'login', query: { redirect: to.fullPath } })
    // next({ name: 'Login', params: { lang: to.params.lang } })
    return
  }

  // Sinon on continue la route sans changements
  next()
})

export default router
