import Vue from 'vue'
import { Fancybox } from '@fancyapps/ui'

Vue.directive('fancybox', {
  bind: function (el, binding, vnode) {
    let customClass
    if (binding.arg) { // Soit on fournit un argument (v-fancybox:arg)
      customClass = `fancybox-${binding.arg}`
    } else if (el.id) { // Soit on fournit un id
      // console.log(el.id)
      customClass = 'fancybox-' + el.id
    }
    el.classList.add(customClass)
    Fancybox.bind(`.${customClass} [data-src]`, binding.value)
  }
})
