<template>
  <div>
    <Header />
    <v-container>
      <v-alert
        color="blue"
        type="success"
        text
      >
        {{ $t('pas-de-commande-alert') }}
      </v-alert>
    </v-container>
  </div>
</template>

<script>
import Header from '@/components/Header'

export default {
  name: 'Home',
  components: {
    Header
  }
}
</script>
