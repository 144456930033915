export default [
  {
    path: 'commande/:id',
    name: 'Commande',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Commande/Commande.vue')
  },
  {
    path: 'commande/:id/packing-list',
    name: 'PackingList',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Commande/PackingList.vue')
  }
]
