import Vue from 'vue'
import Vue2Filters from 'vue2-filters'
import { DateTime } from 'luxon'

const Vue2FiltersConfig = {
  capitalize: {
    onlyFirstLetter: false
  },
  number: {
    format: '0',
    thousandsSeparator: ',',
    decimalSeparator: '.'
  },
  bytes: {
    decimalDigits: 2
  },
  percent: {
    decimalDigits: 2,
    multiplier: 100,
    decimalSeparator: '.'
  },
  currency: {
    symbol: '€',
    decimalDigits: 2,
    thousandsSeparator: ' ',
    decimalSeparator: ',',
    symbolOnLeft: false,
    spaceBetweenAmountAndSymbol: true,
    showPlusSign: false
  },
  pluralize: {
    includeNumber: false
  },
  ordinal: {
    includeNumber: false
  }
}

Vue.filter('allLinksToBlank', function (value) {
  return ('' + value).replace(/<a\s+href=/gi, '<a target="_blank" href=')
})

Vue.filter('putEmailEndOfPiscinelleLinks', function (value, email) {
  var urlRegex = /(?:https?:\/\/)?(?:www\.)?piscinelle\.com(\/([a-zA-Z0-9-.|~_]+))*/ig
  return value.replace(urlRegex, function (url) {
    return url + '?email=' + email
  })
})

Vue.filter('replaceInterrogationByAnd', function (value) {
  var urlRegex = /<a[^>]*href=["']([^"']*)["']/g
  return value.replace(urlRegex, function (url) {
    return url.replace(/[?]([^?]*)$/, '&$1')
  })
})

Vue.filter('packingListAddTutokey', function (value) {
  var tutoKey = window.btoa(DateTime.now().plus({ months: 1 }).toFormat('yyyy-MM-dd'))
  var urlRegex = /<a [^>]*\bhref\s*=\s*"([^"]*guide[^"]*)/
  return value.replace(urlRegex, function (url) {
    return url + '&key=' + tutoKey
  })
})

Vue.use(Vue2Filters, Vue2FiltersConfig)
