import Vue from 'vue'

export default {
  state: {
    list: {}
  },
  getters: {

  },
  mutations: {
    SET_CONTENU (state, contenu) {
      state.list[contenu.co_id] = contenu
    }
  },
  actions: {
    getContenu ({ commit, state }, contenuId) {
      return new Promise((resolve, reject) => {
        if (contenuId in state.list) return resolve(state.list[contenuId])

        Vue.prototype.$api.get('/espace-client/contenu/' + contenuId)
          .then(res => {
            commit('SET_CONTENU', res.data)
            resolve(res.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    }
  }
}
