import router from '@/router'
import Vue from 'vue'

export default {
  state: {
    list: [],
    full: {},
    suppression: false,
    loaded: false
  },
  getters: {
    getCommandeById: (state) => (id) => {
      return state.full[id]
    },
    suppression: state => state.suppression,
    commandesLoaded: state => state.loaded
  },
  mutations: {
    SET_COMMANDES (state, commandes) {
      state.list = commandes
    },
    SUPPRESSION_SUCCESS (state) {
      state.suppression = true
    },
    COMMANDES_FULL_LOADED (state) {
      state.loaded = true
    },
    ADD_COMMANDE (state, commande) {
      state.full = {
        ...state.full,
        [commande.n]: {
          ...commande
        }
      }
    }
  },
  actions: {
    getCommandes ({ commit, state }, force = false) {
      return new Promise((resolve, reject) => {
        if (state.list.length > 0 && !force) return resolve(state.list)

        Vue.prototype.$api.get('/espace-client/mes-commandes')
          .then(res => {
            commit('SET_COMMANDES', res.data)
            commit('COMMANDES_FULL_LOADED')
            resolve(res.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    suppression ({ commit, dispatch }, commandeId) { // Supprime une config internaute
      return new Promise((resolve, reject) => {
        var params = {
          commandeId: commandeId
        }
        Vue.prototype.$api.post('/espace-client/supprimer-config/', params)
          .then(res => {
            commit('SUPPRESSION_SUCCESS')
            dispatch('getCommandes', true).then((commandes) => {
              if (commandes.length) {
                router.push({ name: 'Commande', params: { id: commandes[0].id } })
                resolve(res.data)
              } else {
                router.push({ name: 'Commandes' })
                resolve(res.data)
              }
            }).catch(() => {})
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    getCommande ({ commit, state }, id) {
      return new Promise((resolve, reject) => {
        // Si commande déjà recup on l'affichage sinon on va la charger via une requête axios
        if (id in state.full) return resolve(state.full[id])

        Vue.prototype.$api.get(`/espace-client/commande/${id}`)
          .then(res => {
            commit('ADD_COMMANDE', res.data)
            resolve(res.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    }
  }
}
