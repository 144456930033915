import Vue from 'vue'
import Vuex from 'vuex'

import user from '@/store/modules/user'
import commandes from '@/store/modules/commandes'
import contenus from '@/store/modules/contenus'
import loader from '@/store/modules/loader'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user,
    commandes,
    contenus,
    loader
  }
})
