<template>
  <v-img
    :height="$vuetify.breakpoint.xs ? '400px' : '300px' "
    class="commande-header align-end d-flex stretch align-stretch"
    :src="banniere ? banniere : (user.is_petite_source ? piscinelleUrl+'img/PS/home.jpg' : 'https://www.piscinelle.com/upload/piscine-design-ete-2021-18-L-nologo.jpg')"
    :position="banniere ? 'center 80%' : (user.is_petite_source ? 'bottom' : 'center')"
    gradient="to top right, rgba(0,0,0,.3), rgba(0,0,0,.3)"
  >
    <v-container class="d-flex flex-column justify-space-between align-start fill-height">
      <div :class="$vuetify.breakpoint.mdAndDown ? 'mt-12' : 'mt-6'">
        <v-btn
          v-if="backTo"
          light
          rounded
          elevation="0"
          small
          class="px-1"
          :to="backTo"
        >
          <v-icon dense>
            mdi-chevron-left
          </v-icon>
          {{ backText }}
        </v-btn>
      </div>
      <div>
        <div
          class="title text-sm-h3"
        >
          <slot name="title">
            {{ title }}
          </slot>
        </div>
        <div class="text-sm-body-1">
          <slot name="subtitle">
            {{ subtitle }}
          </slot>
        </div>
      </div>
    </v-container>
  </v-img>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'Header',
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    banniere: {
      type: String,
      default: ''
    },
    backTo: {
      type: [Object, Boolean],
      default: false
    },
    backText: {
      type: String,
      default: 'Retour'
    }
  },
  data () {
    return {
      piscinelleUrl: process.env.VUE_APP_PISCINELLE_URL
    }
  },
  computed: {
    ...mapGetters([
      'user'
    ])
  }
}
</script>
